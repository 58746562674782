import React, { useEffect, useState } from "react";
import axios from "axios";

import Footer from "../components/Footer/footer";

function Teams() {
  const [teams, setTeamsdata] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    try {
      axios
        .get(
          "https://alienx-tech.ghost.io/ghost/api/content/pages/?key=f8d3b35e030c2ef9635226093a&filter=tag:TermsandConditions"
        )
        .then((response) => {
          setTeamsdata(response.data.pages[0]);
        });
    } catch (err) {
      return err;
    }
  }, []);
  return (
    <div>
      <div id="header" className="header"></div>
        {loading ? (
    

          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <div>
          <div id="privacy">
          <div dangerouslySetInnerHTML={{ __html: teams.html }}></div>
          </div>
          <Footer />
          </div>
        )}
     
    </div>
  );
}

export default Teams;
