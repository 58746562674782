import React from "react";

function clients({data}) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <h2 className="section-title">Business Partner</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-24">
          <div className="d-flex align-items-center">
          {data.map(({ title, feature_image }) => {
              return (
                <a href={title} className="text-center d-block outline-0 p-4">
                <img
                  className="d-unset img-fluid"
                  src={feature_image}
                  alt="client-logo"
                />
              </a>
              );
            })}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default clients;
