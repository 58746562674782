import React from "react";
import { Link } from "react-router-dom";

function footer() {
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4>
                You can reach the team at{" "}
                <a className="purple" href="mailto:support@alienx.tech">
                  support@alienx.tech
                </a>
              </h4>
              <div className="social-container">
                <span className="fa-stack">
                  <a
                    href="https://twitter.com/AlienX_Tech"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fab fa-twitter fa-stack-1x" />
                  </a>
                </span>
                <span className="fa-stack">
                  <a
                    href="https://www.linkedin.com/company/26663030"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fab fa-linkedin fa-stack-1x" />
                  </a>
                </span>
                <span className="fa-stack">
                  <a
                    href="https://www.youtube.com/channel/UC6XfHO_ULoHzpcN8DLDqxoQ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fas fa-circle fa-stack-2x" />
                    <i className="fab fa-youtube fa-stack-1x" />
                  </a>
                </span>
              </div>
              {/* end of social-container */}
            </div>
            {/* end of col */}
          </div>
          {/* end of row */}
        </div>
        {/* end of container */}
      </div>
      {/* end of footer */}
      {/* end of footer */}
      {/* Copyright */}
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <ul className="list-unstyled li-space-lg p-small">
               
                <li>
                 
                    <a href="terms.html">Terms &amp; Conditions</a>
                </li>
                <li>
                    <a>Privacy Policy</a>
                </li>
              </ul>
            </div>{" "}
            {/* end of col */}
            <div className="col-lg-6">
              <p className="p-small statement">
                Copyright © <a href="/">Alienx Tech</a>
              </p>
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* enf of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
      {/* end of copyright */}
      {/* end of copyright */}
    </div>
  );
}

export default footer;
