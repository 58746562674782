import React from "react";
import Routers from "../../routers/router";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Auth from "../../pages/Auth/index";
function Nav() {
  return (
    <Router>
      <nav className="navbar navbar-expand-lg fixed-top navbar-light ">
        <div className="container">
          <Link to="/">
            <a className="navbar-brand logo-image page-scroll" href="#header">
              <img src="images/nav-logo.png" alt="alternative" />
            </a>
          </Link>
          <button
            className="navbar-toggler p-0 border-0"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="navbar-collapse offcanvas-collapse"
            id="navbarsExampleDefault"
          >
            <ul className="navbar-nav ml-auto space"></ul>
              <ul className="navbar-nav ml-auto space">
                <Link to="/">
                  <span className="nav-item app-store-icons">
                    <a
                      className="nav-link btn page-scroll a-remove-underline"
                      href="#header"
                    >
                      Home
                      <span className="sr-only">(current)</span>
                    </a>
                  </span>
                </Link>
                <Link to="/">
                  <span className="nav-item app-store-icons">
                    <a
                      className="nav-link btn page-scroll a-remove-underline"
                      href="#features"
                    >
                      Features
                    </a>
                  </span>
                </Link>
                <Link to="/">
                  <span className="nav-item app-store-icons ">
                    <a className="nav-link btn page-scroll" href="#details">
                      Details
                    </a>
                  </span>
                </Link>
                <Link to="/">
                  <span className="nav-item app-store-icons ">
                    <a className="nav-link btn page-scroll" href="#clientsandcustomer">
                    Clients
                    </a>
                  </span>
                </Link>
              </ul>
           
           
            {/* <Link to="/services">
              <span className="nav-item app-store-icons ">
                <a className="nav-link btn page-scroll">Services</a>
              </span>
            </Link> */}
            {/* <Link to="/">
              <span className="nav-item app-store-icons">
                <a className="nav-link btn page-scroll" href="#download">
                  Download
                </a>
              </span>
            </Link>
            <a
              href="https://play.google.com/store/apps/details?id=in.alienx"
              target="_blank"
              rel="noreferrer"
            >
              <span className="nav-item app-store-icons btn">

                <i className="fab fa-android" />
              </span>
            </a> */}

            <span className="nav-item app-store-icons "></span>

            {/* <Link to="/">
            <span className="nav-item app-store-icons  ">
                <a
                  className="nav-link btn page-scroll a-remove-underline"
                  href="#exampleModal" data-toggle="modal"
                >
                  Sign Up/login
                </a>
              </span>
            
            </Link>
            <Router /> */}
          </div>
        </div>
      </nav>
      <div>
        <Auth />
        <Routers />
      </div>
    </Router>
  );
}

export default Nav;
