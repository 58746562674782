module.exports = {
  home: "https://alienx-tech.ghost.io/ghost/api/content/settings/?key=f8d3b35e030c2ef9635226093a",
  features:
    "https://alienx-tech.ghost.io/ghost/api/content/pages/?key=f8d3b35e030c2ef9635226093a&filter=tag:Features",
  details:
    "https://alienx-tech.ghost.io/ghost/api/content/pages/?key=f8d3b35e030c2ef9635226093a&filter=tag:Details",
  businesspartner:
    "https://alienx-tech.ghost.io/ghost/api/content/pages/?key=f8d3b35e030c2ef9635226093a&filter=tag:businesspartner",
  counter:"https://alienx-tech.ghost.io/ghost/api/content/pages/?key=f8d3b35e030c2ef9635226093a&filter=tag:counter",
  twitterposts:"https://api.twitter.com/2/users/1300621924347699201/mentions",
  socialmedia:
    "https://alienx-tech.ghost.io/ghost/api/content/pages/?key=f8d3b35e030c2ef9635226093a&filter=tag:socialmedia",
};
