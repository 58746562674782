import React from "react";
import Footer from "../../components/Footer/footer";

function services() {
  return (
    <div>
      <div id="header" className="header"></div>
      <Footer />
    </div>
  );
}

export default services;
