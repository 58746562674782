import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./auth.css";

function Login() {
    const [userDetails, setuserDetails] = useState({
        name: "Demo",
        mail: "Demo@demo.com",
        password: "Password",
    });
    return (
        
        <div className="modal-body">
            <div className="column" id="signupmain">
                <h1>Sign Up </h1>
                <h3>Light up, everybody, join us in the celebration</h3>
                <form>
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            type="name"
                            className="form-control"
                            onChange={(ev) =>
                                setuserDetails({
                                    ...userDetails,
                                    name: ev.target.value,
                                })
                            }
                            id="exampleInputName"
                            placeholder={userDetails.name}
                        />
                    </div>
                    <div className="form-group">
                        <label>E-mail </label>
                        <input
                            type="email"
                            className="form-control"
                            onChange={(ev) =>
                                setuserDetails({
                                    ...userDetails,
                                    email: ev.target.value,
                                })
                            }
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder={userDetails.mail}
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="exampleInputPassword1"
                            onChange={(ev) =>
                                setuserDetails({
                                    ...userDetails,
                                    password: ev.target.value,
                                })
                            }
                            placeholder={userDetails.password}
                        />
                    </div>
                    <button type="button" className="btn btn-primary color">
                        Sign Up
                    </button>
                </form>
            </div>

          
            <div className="column" id="secondary">
                <div className="sec-content">
                    <h2 className="white-text">Welcome Back!</h2>

                    <h3 className="white-text">
                        This place hasn’t been the same without you
                    </h3>
                    <Link to="/signin">
                    <button type="button" className="white-text btn btn-primary">
                        Login
                    </button>
                    </Link>

                </div>
            </div>
        </div>
        


    );
}
export default Login;
