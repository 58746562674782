import React, { useEffect, useState } from "react";
import axios from "axios";

import BlogCard from "../../components/BlogCard/blogcard";
import Footer from "../../components/Footer/footer";
import config from "../../config/index";

import "./blog.css"

function Blog() {
  const [Pagedata, setpagedata] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    try {
      axios.get("https://alienx-tech.ghost.io/ghost/api/content/posts/?key=f8d3b35e030c2ef9635226093a").then((response) => {
        setpagedata(response.data.posts);
      });
    } catch (err) {
      return err;
    }
  }, []);
  return (
    <div>
       {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ):
      <div>
      <div id="header" className="header">
        <div class="col-12 text-center">
          <h1 class="text-black font-tertiary">Blogs</h1>
        </div>
      </div>
      
      <div className="container">
        <div className="row">
        {Pagedata.map(({ title, excerpt, feature_image,url }) => {
                return (
                  <BlogCard
                  title={title}
                  img={feature_image}
                  excerpt={excerpt}
                  url={url}
                />
                );
              })}
         
        </div>
      </div>
      <Footer />
</div>
      }
    </div>
  );
}

export default Blog;
