import React, { useEffect, useState } from "react";

//Components
import FeaturesBox from "../../components/FeaturesBox/FeaturesBox.jsx";
import DetailsBox from "../../components/DetailsBox/detailsBox";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import Counter from "../../components/Counter/counters";
import Clients from "../../components/Clients/clients.jsx";

function Home() {
  const [Pagedata, setpagedata] = useState([
    {
      title: "Empowering the Future with AlienX",
      description:
        "Our mission is to revolutionize industries through advanced technology, driving progress and shaping a world where the extraordinary becomes the norm.",
    },
  ]);
  const [Featuresdata, setFeaturespagedata] = useState([
    {
      title: "Custom Software Development:",
      excerpt:"AlienX Technologies specializes in developing custom software solutions tailored to meet the unique needs of businesses across various industries",
      feature_image: "../images/features-icon-1.svg",
    },
    {
      title: "Artificial Intelligence",
      excerpt: "Leveraging the power of AI, AlienX Technologies offers solutions for machine learning, natural language processing, computer vision, and predictive analytics.",
      feature_image: "../images/features-icon-2.svg",
    },
    {
      title: "Blockchain Solutions",
      excerpt: " AlienX Technologies develops blockchain-based solutions for decentralized applications (dApps), smart contracts, tokenization, and secure transactions.",
      feature_image: "../images/features-icon-3.svg",
    },
    {
      title: "Internet of Things (IoT) ",
      excerpt: "With expertise in IoT technology, AlienX Technologies creates IoT-enabled devices, platforms, and applications for smart homes, industrial automation, healthcare, and more.",
      feature_image: "../images/features-icon-4.svg",
    },
    {
      title: "Cybersecurity Services",
      excerpt: "AlienX Technologies provides cybersecurity services including vulnerability assessments, penetration testing, threat detection, and incident response to safeguard businesses against cyber threats.",
      feature_image: "../images/features-icon-5.svg",
    },
    {
      title: "Mobile App",
      excerpt: "AlienX Technologies develops native and cross-platform mobile applications for iOS and Android, catering to diverse business requirements and user experiences.",
      feature_image: "../images/features-icon-6.svg",
    },
  ]);
  const [Detailsdata, setDetailspagedata] = useState([
    {
      title: "About Us",
      excerpt:
        "Learn more about AlienX Technologies and our commitment to innovation and excellence. Discover our team of experts and the values that guide us in our pursuit of technological advancement. Find out how we're making a difference in the world of tech.",
      feature_image: "../images/startup.png",
    },
    {
      title: "Services",
      excerpt:
        "Explore our diverse portfolio of services tailored to meet the unique needs of businesses across industries. From software development and AI solutions to blockchain integration and cybersecurity, we offer comprehensive services to help you stay ahead of the curve.",
      feature_image: "../images/mission.jpg",
    },
  ]);
  const [Businesspartnerdata, setBusinesspartnerpagedata] = useState([
    {
      title: "one",
      feature_image: "../images/clients-logo/client-logo-1.png",
    },
    {
      title: "one",
      feature_image: "../images/clients-logo/client-logo-2.png",
    },
    {
      title: "one",
      feature_image: "../images/clients-logo/client-logo-3.png",
    },
    {
      title: "one",
      feature_image: "../images/clients-logo/client-logo-4.png",
    },
    {
      title: "one",
      feature_image: "../images/clients-logo/client-logo-5.png",
    },
    {
      title: "one",
      feature_image: "../images/clients-logo/client-logo-6.png",
    },
    {
      title: "one",
      feature_image: "../images/clients-logo/client-logo-7.png",
    },
  ]);
  const [Counterdata, setCounterpagedata] = useState([
    {
      title: "clienct",
      excerpt: "1",
    },
    {
      title: "Projects",
      excerpt: "0",
    },
    {
      title: "Projects",
      excerpt: "1",
    },
  ]);

  return (
    <div>
      <div id="header" className="header">
        <Header Data={Pagedata} />
      </div>
      <div className="basic-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>
                " We have been working hard to bring our customers the best
                possible solutions for their needs. "
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
      <div id="features" className="cards-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {Featuresdata.map(({ title, excerpt, feature_image }) => {
                return (
                  <FeaturesBox
                    title={title}
                    img={feature_image}
                    discreption={excerpt}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div id="details" className="basic-2">
        <div className="basic-3">
          <div className="container">
            {Detailsdata.map(({ title, excerpt, feature_image }) => {
              return (
                <DetailsBox
                  title={title}
                  imgSrc={feature_image}
                  discreption={excerpt}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div id="clientsandcustomer" className="basic-2">
        <div className="basic-3">
          <div className="container">
            <Clients data={Businesspartnerdata} />
            <Counter data={Counterdata} />
          </div>
        </div>
      </div>
      {/* Conclusion */}
      {/* <div id="download" className="basic-5">
        <DownloadBox />
      </div> */}
      <Footer />
    </div>
  );
}

export default Home;
