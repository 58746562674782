import React from "react";
import Signup from "./signup";
import Login from "./login";
import "./auth.css";

function Index() {
  return (
    <div
      className="modal fade"
      id="exampleModal"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="fales"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <Login />
        </div>
      </div>
    </div>
  );
}

export default Index;
