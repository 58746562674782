import React, { useState } from "react";
import { useEffect } from "react";
import ReactGA from "react-ga";
import "./app.css";

import Nav from "./components/Nav/nav";

// YOUR_OWN_TRACKING_ID
const TRACKING_ID = "UA-201063088-1";

ReactGA.initialize(TRACKING_ID);
function App() {
  const [location,setLocation]=useState({})
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, []);
  useEffect(()=>{
    // navigator.geolocation.getCurrentPosition(function(position) {
    //   setLocation({latitude:position.coords.latitude,longitude:position.coords.longitude})
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    // });
  },[])
 
  return (
    <div className="App">
       <Nav />
    </div>
  );
}

export default App;
