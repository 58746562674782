import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../pages/Home/home";
import SignupPage from "../pages/Auth/signup";
import AuthPage from "../pages/Auth/index";
import LoginPage from "../pages/Auth/login";
import PrivacypolicyPage from "../pages/Privacy";
import ServicesPage from "../pages/Services/services";
import BlogPage from "../pages/Blog/blog";
import TermsPage from "../pages/teams";

export default function router() {
  return (
    <Switch>

      <Route default exact path="/">
        <Home />
      </Route>
      <Route default path="/auth">
        <AuthPage />
      </Route>
      <Route default path="/signup">
        <SignupPage />
      </Route>
      <Route default path="/signin">
        <LoginPage />
      </Route>
      <Route default path="/services">
        <ServicesPage />
      </Route>
      <Route default path="/blog">
        <BlogPage />
      </Route>
      <Route default path="/privacy-policy">
        <PrivacypolicyPage />
      </Route>
      <Route default path="/terms-and-conditions">
        <TermsPage />
      </Route>
    </Switch>
  );
}
