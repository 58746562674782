import React, { useState,useEffect } from "react";
import "./auth.css";

import axios from "axios";

import { Link } from "react-router-dom";


function Login() {

    const [userDetails, setuserDetails] = useState({
        mail: "Demo@demo.com",
        password: "Password",
    });
    const getlogin=() => {
        try {
          axios
            .post(
              "http://localhost:3001/signin",{ 
                mail: userDetails.mail,
                password: userDetails.password
              }
            )
            .then((response) => {
                userDetails(response.data);
                console.log(response)
            });
        } catch (err) {
          return err;
        }
      }
    return (
        
        <div className="modal-body">
            <div className="column" id="main">
                <h1>Login Welcome Back! </h1>
                <h3>This place hasn’t been the same without you</h3>
                <form>
                    
                    <div className="form-group">
                        <label>E-mail </label>
                        <input
                            type="email"
                            className="form-control"
                            onChange={(ev) =>
                                setuserDetails({
                                    ...userDetails,
                                    email: ev.target.value,
                                })
                            }
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder={userDetails.mail}
                        />
                    </div>
                    <div className="form-group">
                        <label >Password</label>
                        <input
                            type="password"
                            className="form-control"
                            id="exampleInputPassword1"
                            onChange={(ev) =>
                                setuserDetails({
                                    ...userDetails,
                                    password: ev.target.value,
                                })
                            }
                            placeholder={userDetails.password}
                        />
                    </div>
                    <button type="button" onClick={getlogin} className="btn btn-primary color">
                       login
                    </button>
                </form>
            </div>

          
            <div className="column" id="secondary">
                <div className="sec-content">
                    <h2 className="white-text"></h2>
                            JOIN WITH US
                    <h3 className="white-text">
                    Light up, everybody, join us in the celebration
                    </h3>
                    <span className="nav-item app-store-icons  ">
                <a
                  className="nav-link btn page-scroll a-remove-underline"
                  href="#signupmain" data-toggle="modal"
                >
                  Sign Up/login
                </a>
              </span>
                </div>
            </div>
        </div>
        


    );
}
export default Login;
