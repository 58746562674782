import React from "react";

function detailsBox({ imgSrc, title, discreption }) {
  return (
    <div>
      <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="text-container">
                <h2>{title}</h2>
                <p>
                 {discreption}
                </p>
              </div>{" "}
              {/* end of text-container */}
            </div>{" "}
            {/* end of col */}
            <div className="col-lg-7">
              <div className="image-container">
                <img
                  className="img-fluid"
                  src={imgSrc}
                  alt="alternative"
                />
              </div>{" "}
              {/* end of image-container */}
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
        </div>{" "}
    </div>
  );
}

export default detailsBox;
