import React from "react";

function FeaturesBox({ title, discreption, img }) {
  return (
    <div className="card">
      <div className="card-image">
        <img className="img-fluid" src={img} alt="alternative" />
      </div>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        <p>{discreption}</p>
      </div>
    </div>
  );
}

export default FeaturesBox;
