import React, { useEffect, useState } from "react";
import axios from "axios";

import Footer from "../components/Footer/footer";

function Privacy() {
  const [privacy, setPrivacydata] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    try {
      axios
        .get(
          "https://alienx-tech.ghost.io/ghost/api/content/pages/?key=f8d3b35e030c2ef9635226093a&filter=tag:privacypolicy"
        )
        .then((response) => {
          setPrivacydata(response.data.pages[0]);
        });
    } catch (err) {
      return err;
    }
  }, []);
  return (
    <div>
      <div id="header" className="header"></div>
      {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
      ):
      <div>
      <div id="privacy">

      <div dangerouslySetInnerHTML={{ __html: privacy.html }} ></div>
      </div>
      <Footer />
      </div>

}
     
    </div>
  );
}

export default Privacy;
