import React from "react";

function blogcard(data) {
  return (
    <div className="col-lg-4 col-sm-6 mb-4">
      <article className="card shadow">
        <img className="rounded card-img-top" src={data.img} alt="post-thumb" />
        <div className="card-body">
          <h4 className="card-title">
            <a className="text-dark" href="blog-single.html">
              {data.title}
            </a>
          </h4>
          <p className="cars-text">{data.excerpt}</p>
          <a
            href={data.url}
            target="_blank"
            rel="noreferrer"
            className="btn btn-xs btn-primary"
          >
            Read More
          </a>
        </div>
      </article>
    </div>
  );
}

export default blogcard;
