import React, { useState } from "react";

function Header({Data}) {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="text-container">
              {Data.map(({ title, description }) => {
                return (
                  <>
                    <h1 className="h1-large">{title}</h1>
                    <p className="p-large"> {description}</p>
                  </>
                );
              })}

              <a className="btn-solid-lg page-scroll" href="#details">
                <i className="far fa-address-card"></i> Learn more
              </a>
            </div>{" "}
            {/* end of text-container */}
          </div>{" "}
          {/* end of col */}
          <div className="col-lg-6">
            <div className="image-container">
                    <img
                      className="img-fluid"
                      src='../images/startup-launch.png'
                    />
            </div>{" "}
            {/* end of image-container */}
          </div>{" "}
          {/* end of col */}
        </div>{" "}
      </div>
    </div>
  );
}

export default Header;
