import React from "react";

function counters({ data }) {
  return (
    <div>
      <div className="counter">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* Counter */}
              <div id="counter">
                {data.map(({ title, excerpt }) => {
                  return (
                    <div className="cell">
                      <div
                        className="counter-value number-count"
                        data-count={excerpt}
                      >
                        0
                      </div>
                      <p className="counter-info">{title}</p>
                    </div>
                  );
                })}
              </div>
              {/* end of counter */}
            </div>{" "}
            {/* end of col */}
          </div>{" "}
          {/* end of row */}
        </div>{" "}
        {/* end of container */}
      </div>{" "}
    </div>
  );
}

export default counters;
